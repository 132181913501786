
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import { Component, Vue } from 'vue-property-decorator'
import Payment from '@/models/Payment'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import InvoicePicker from '@/components/InvoicePicker/InvoicePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'

import { currencyMask } from '@/models/interface/Masks'
// @ts-ignore
import { VueMaskDirective } from 'v-mask'
import Invoice from '@/models/Invoice'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import { PaymentMethods } from '@/models/interface/Common'
import WebMessage from '@/models/WebMessage'

Vue.directive('mask', VueMaskDirective)

@Component({
  components: {
    Widget,
    FooterNav,
    FormInput,
    InvoicePicker,
    SelectPicker,
    DatePicker,
    ModelFileUploader,
  },
})
export default class PaymentsView extends ViewModel {
  // @ts-ignore
  public payment = new Payment()

  public busy: boolean = true

  public loading: boolean = true

  public get masks() {
    return { currencyMask }
  }

  public get _isEditing() {
    return !!this.$route.params.id
  }

  public get pageTitle() {
    return this._isEditing ? 'Edit payment' : 'New Payment'
  }

  public get payment_options() {
    return PaymentMethods
  }

  public checkDue() {
    if (!this._isEditing && this.payment.invoice_id?.length) {
      // @ts-ignore
      Invoice.find(this.payment.invoice_id).then((data: Invoice) => {
        // @ts-ignore
        Vue.set(this.payment, 'invoice', {})
        Vue.set(this.payment.invoice, 'due', data.due)

        return data
      })
    }
  }

  public loadPayment() {
    Payment.find(this.$route.params.id).then(data => {
      this.payment = data
      this.loading = false
      this.busy = false
      return data
    })
  }

  public savePaymentDetails() {
    this.loading = true
    if (!this.payment.conflict) {
      this.savePayment()
      return
    }
    WebMessage.confirm(
      'This payment was updated on Quickbooks and it might have conflicts, are you sure that you want to update the this record?',
    ).then((confirmed: boolean) => {
      if (confirmed) {
        this.savePayment()
      } else {
        this.loading = false
      }
    })
  }

  private savePayment() {
    this.payment.name = this.payment.invoice.name
    this.payment
      .save()
      .then(() => {
        this.loading = false
        this.$router.push({ name: 'payments' })
      })
      .catch(() => {
        this.loading = false
      })
  }

  mounted() {
    if (!this._isEditing) {
      this.loading = false
      this.busy = false
    } else {
      this.loadPayment()
    }
  }
}
